
import { useState } from 'react';

function App() {
  const [mouseDown, setMouseDown] = useState(false)

  document.onmousedown = function() { setMouseDown(true) }
  document.onmouseup = function() { setMouseDown(false) }


  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });

  document.addEventListener('ontouchstart', function(e) {
    console.log(e)
  })

  let pixels = [];
  for (let i = 1; i <= 784; i++) {
    pixels.push(i);
  }


  function toggleByClick(id) {
    const el = document.getElementById(id.i)
    if(!el) return;

    if (el.className == "pixel clicked") {
      el.classList.remove('clicked');
    } else {
      el.classList.add('clicked');
    }
  }

  function enableByDrag(id) {
    if (!mouseDown) return;

    const el = document.getElementById(id.i)
    if(!el) return;

    el.classList.add('clicked');
  }

  function touchDrag(id) {

    const el = document.getElementById(id.i)
    if(!el) return;

    el.classList.add('clicked');
  }

  function run() {
    let output = []
    pixels.map(i => {
      const el = document.getElementById(i);
      if (el.className == "pixel clicked") {
        output.push(1)
      } else {
        output.push(0)
      }

    })
    console.log(output.length)
    console.log(output)

    generateCsv(output)
  }

  function generateCsv(array) {
    let csvContent = "data:text/csv;charset=utf-8,";
    let row = array.join(",");
    csvContent += row + "\r\n";

    let encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  function clearPixes() {
    pixels.map(i => {
      const el = document.getElementById(i);
      el.classList.remove('clicked');

    })
  }

  return (
    <>
    <div className="pixel-grid">
       {pixels.map(i => (
          <button className="pixel" onClick={() => toggleByClick({i})} onMouseOver={() => enableByDrag({i})} onTouch={() => touchDrag({i})} id={i.toString()} key={i}></button>
       ))}
    </div>

    <div className="center">
      <button onClick={() => run()}>Run</button>
      <button onClick={() => clearPixes()}>Clear</button>
      {/* <textarea></textarea> this will be needed for output*/}
    </div>
    </>

  );
}

export default App;
